export interface TutorialRoute {
    url: string;
    nome: string;
    textoEntrada?: string;
    abrirCadastro?: boolean;
}

export interface TutorialConfig {
    rotas: TutorialRoute[];
    telaFinal?: string;
    nomeTelaFinal?: string;
    nomeService?: string;
}

export const tutorialsConfig: Record<string, TutorialConfig> = {
    // 'Agendar Atendimentos': {
    //     rotas: [
    //         {
    //             url: '/meusClientes',
    //             nome: 'Cadastro de Paciente',
    //             textoEntrada: 'Vamos criar uma agenda e confirmar com seu paciente. Adicione o seu primeiro PACIENTE agora.',
    //             abrirCadastro: true,
    //         },
    //         // {
    //         //     url: '/localidades',
    //         //     nome: 'Criar Local de Atendimento',
    //         //     textoEntrada: 'Agorra precisamos cadastrar um local de atendimento para você. Aonde você faz seus atendimentos presenciais é um bom começo. Só clicar no botão + abaixo e seguir...',
    //         //     abrirCadastro: true,
    //         // },
    //         {
    //             url: '/servicos',
    //             nome: 'Criar Serviço',
    //             textoEntrada: 'Ótimo! Agora vamos cadastrar um dos seus serviços que você oferece. Clique no botão + abaixo e siga as instruções.',
    //             abrirCadastro: true,
    //         },
    //         {
    //             url: '/calendario',
    //             nome: 'Cadastro de Agenda',
    //             textoEntrada: 'Maravilha! Agora você está vendo sua agenda (em branco). Vamos adicionar um atendimento?',
    //             abrirCadastro: true,
    //         },
    //     ],
    //     telaFinal: '/calendario',
    //     nomeTelaFinal: 'Sua agenda foi criada. O seu paciente irá receber uma mensagem de confirmação e você receberá um aviso quando ele confirmar.',
    //     nomeService: 'AGENDA',
    // },
    'Página de Agendamento Automático': {
        rotas: [
            {
                url: '/localidades',
                nome: 'Criar Local de Atendimento',
                textoEntrada: 'Vamos criar seu site de agendamento automático! Primeiramente, configure um local de atendimento onde você oferece seus serviços.',
                abrirCadastro: true,
            },
            {
                url: '/servicos',
                nome: 'Criar Serviço',
                textoEntrada: 'Agora defina os serviços que você oferecerá.',
                abrirCadastro: true,
            },
            {
                url: '/configAgenda',
                nome: 'Cadastro de Agenda',
                textoEntrada: 'Agora vamos configurar sua disponibilidade de agenda em relação aos serviços. Se tiver dúvidas pode abrir o help quando quiser. Quando finalizar salve a configuração criada',
                abrirCadastro: true,
            },
            {
                url: '/perfil',
                nome: 'Atualizar Perfil de Usuário',
                textoEntrada: 'Agora atualize seu perfil, coloque uma imagem, adicione as informações pertinentes e já teremos todas as informações necessárias.',
                abrirCadastro: false,
            },
        ],
        telaFinal: '/perfil',
        nomeTelaFinal: 'Agora seu site de agendamento automático foi criado. Receba agendamentos diretamente dos seus pacientes.',
        nomeService: 'CORPORA_BIO',
    },
    // 'Cobrança Automática de Pacientes': {
    //     rotas: [
    //         {
    //             url: '/onboarding',
    //             nome: 'Cobrança Automática',
    //             textoEntrada: 'Vamos configurar sua chave PIX e depois as regras para habilitar as Cobranças Automáticas.',
    //             abrirCadastro: true,
    //         },
    //         // {
    //         //     url: '/meusClientes',
    //         //     nome: 'Cadastro de Paciente',
    //         //     textoEntrada: 'Cadastre seus pacientes para realizar cobranças.',
    //         //     abrirCadastro: true,
    //         // },
    //         // {
    //         //     url: '/calendario',
    //         //     nome: 'Cadastro de Agenda',
    //         //     textoEntrada: 'Configure sua agenda para atender seus pacientes.',
    //         //     abrirCadastro: false,
    //         // },
    //         // {
    //         //     url: '/lancamentos',
    //         //     nome: 'Verificar Financeiro',
    //         //     textoEntrada: 'Acompanhe o financeiro para gerenciar suas cobranças.',
    //         //     abrirCadastro: false,
    //         // },
    //     ],
    //     telaFinal: '/onboarding',
    //     nomeTelaFinal: 'Cobrança Automática',
    //     nomeService: 'COBRANCA',
    // },
};
