import { Button, Dialog, Switcher } from 'components/ui';
import { HiExclamation } from 'react-icons/hi';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import { useEffect, useState } from 'react';
import SwitcherDouble from 'components/ui/Switcher/SwitcherDouble';
import useDialog from 'views/calendario/hooks/useDialog';

interface DialogProps {
    data: ConfigAgenda | null;
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    cancelamentoConfirmaClick: (options: {
        cobrar: number;
        responsavel: 'profissional' | 'paciente';
        cancelarAgendasSeguintes: number;
    }) => void;
}

const DialogConfirmarCancelamento = ({
    data,
    isOpen,
    onSuccess,
    onClose,
}: DialogProps) => {
    const [cobrar, setCobrar] = useState<number>(0);
    const [responsavel, setResponsavel] = useState<'profissional' | 'paciente'>('paciente');
    const [cancelarAgendasSeguintes, setCancelarAgendasSeguintes] = useState<number>(0);
    const { cancelamentoConfirma } = useDialog();

    useEffect(() => {
        if (isOpen) {
            setCobrar(0);
            setResponsavel('paciente');
            setCancelarAgendasSeguintes(0);
        }
    }, [isOpen]);

    useEffect(() => {
        if (cancelarAgendasSeguintes === 1) {
            setCobrar(0);
        }
    }, [cancelarAgendasSeguintes]);

    const handleConfirm = () => {
        const isResponsavelPaciente = responsavel === 'paciente' ? 0 : 1;
        cancelamentoConfirma(data!, onSuccess, isResponsavelPaciente, cobrar, cancelarAgendasSeguintes);
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            <div className="p-6 bg-white">
                <h6 className="mb-4 font-medium">Cancelamento de agenda: {data?.dataInicio}</h6>

                {data?.agrupamento && (
                    <>
                        <span className="mb-4 font-medium">Detectamos que essa agenda é recorrente</span>
                        <div className="flex items-center gap-4 my-4">
                            <div className="flex items-center">
                                <Switcher
                                    checked={cancelarAgendasSeguintes === 1}
                                    onChange={() => setCancelarAgendasSeguintes(cancelarAgendasSeguintes === 1 ? 0 : 1)}
                                />
                                <span className="ml-3">Marcar agendas seguintes como canceladas?</span>
                            </div>
                        </div>
                    </>
                )}

                <div className="flex items-center gap-4 mb-10">
                    <div className="flex items-center w-80">
                        <Switcher
                            checked={cobrar === 1}
                            onChange={() => setCobrar(cobrar === 1 ? 0 : 1)}
                            disabled={cancelarAgendasSeguintes === 1}
                        />
                        <span className={`ml-3 ${cancelarAgendasSeguintes === 1 ? 'text-gray-300' : ''}`}>
                            Agendamento será cobrado
                        </span>
                    </div>
                </div>

                <div className="gap-4 my-4 mb-10">
                    <span>Responsável pelo cancelamento</span>
                    <div className="flex gap-4 mt-4">
                        <span className="text-right">Profissional</span>
                        <SwitcherDouble
                            checked={responsavel === 'paciente'}
                            onChange={() => setResponsavel(responsavel === 'profissional' ? 'paciente' : 'profissional')}
                        />
                        <span className="text-left">Paciente</span>
                    </div>
                </div>

                <p className="mb-5 mt-10">
                    Esse cancelamento <b>não poderá ser desfeito</b>. Confirma?
                </p>

                <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                    <Button variant="solid" onClick={handleConfirm}>
                        Confirmar
                    </Button>
                    <Button onClick={onClose}>Cancelar</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogConfirmarCancelamento;
