import { Button, Dialog, Switcher } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';
import { useState, useEffect } from 'react';

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    data: ConfigAgenda | null;
    onSucess: () => void;
}

const DialogConfirmarRealizar = ({
    data,
    isOpen,
    onClose,
    onSucess,
}: DialogProps) => {
    const { realizarConfirmaClick } = useDialog();

    const [valorRecebido, setValorRecebido] = useState<number>(0);
    const [cobrarValor, setCobrarValor] = useState<number>(0);

    useEffect(() => {
        if (isOpen) {
            setValorRecebido(0);
            setCobrarValor(0);
        }
    }, [isOpen]);


    useEffect(() => {
        if (valorRecebido === 1) {
            setCobrarValor(0);
        }
    }, [valorRecebido]);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            <h6 className="mb-4 font-medium">Marcar agenda como realizada?</h6>
            <p>{data?.dataInicio}</p>
            <p>
                Após marcar a agenda como <strong>REALIZADA</strong> não será possível
                editar as informações do agendamento. <br />

            </p>

            <div className="flex items-center gap-4 my-4">
                <div className="flex items-center w-80">
                    <Switcher
                        checked={valorRecebido === 1}
                        onChange={() => setValorRecebido(valorRecebido === 1 ? 0 : 1)}
                    />
                    <span className="ml-3">Marcar no financeiro como recebido?</span>
                </div>
            </div>

            <div className="flex items-center gap-4 my-4">
                <div className="flex items-center w-80">
                    <Switcher
                        checked={cobrarValor === 1}
                        onChange={() => setCobrarValor(cobrarValor === 1 ? 0 : 1)}
                        disabled={valorRecebido === 1}
                    />
                    <span className="ml-3">Enviar cobrança da consulta?</span>
                </div>
            </div>

            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button
                    variant="solid"
                    onClick={() => {
                        if (!data) return;
                        realizarConfirmaClick(data, onSucess, valorRecebido, cobrarValor)
                    }}
                >
                    Confirmar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
            </div>
        </Dialog>
    );
};

export default DialogConfirmarRealizar;
