import { Button, Dialog, Switcher } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';
import { useEffect, useState } from 'react';
import SwitcherDouble from 'components/ui/Switcher/SwitcherDouble';

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    data: ConfigAgenda | null;
    onCancelAgenda: () => void;
}

const DialogNaoCompareceuCobranca = ({
    data,
    isOpen,
    onClose,
    onCancelAgenda,
}: DialogProps) => {
    const [manterCobranca, setManterCobranca] = useState<number>(0);
    const [enviarMensagem, setEnviarMensagem] = useState<number>(0); 

    const { naoCompareceuConfirma } = useDialog();

    useEffect(() => {
        if (isOpen) {
            setManterCobranca(0);  
            setEnviarMensagem(0);  
        }
    }, [isOpen]);

    

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            <div className="p-6 bg-white">
                <h6 className="mb-4 font-medium">O seu paciente não compareceu ao atendimento.</h6>
                
                <div className="flex items-center gap-4 my-4">
                    <div className="flex items-center  w-80">
                        <Switcher
                            checked={manterCobranca === 1}
                            onChange={() => setManterCobranca(manterCobranca === 1 ? 0 : 1)}
                        />
                        <span className="ml-3">Agendamento será cobrado</span>
                    </div>
                </div>
                
                <div className="flex items-center gap-4 my-4">
                    <div className="flex items-center" style={{ width: "22rem" }}>
                        <Switcher
                            checked={enviarMensagem === 1}
                            onChange={() => setEnviarMensagem(enviarMensagem === 1 ? 0 : 1)}
                        />
                        <span className="ml-2">Enviar mensagem reagendamento</span>
                    </div>
                </div>
                <p>
                    Você está marcando a agenda com o não comparecimento do seu paciente.
                    Confirma a ação??
                </p>

                <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                    <Button
                        variant="solid"
                        onClick={() => {
                            if (!data) return;
                            naoCompareceuConfirma(data, onCancelAgenda, manterCobranca, enviarMensagem);
                        }}
                    >
                        Confirmar
                    </Button>
                    <Button onClick={onClose}>Cancelar</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogNaoCompareceuCobranca;
